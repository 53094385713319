textarea::placeholder {
  color: green !important;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their intrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
  max-width: 100%;
  height: auto;
}

.svg-decal {
  fill: currentColor;
  margin-bottom: 48px;
  filter: drop-shadow(1px -15px 8px hsla(0, 0%, 0%, 0.2));
}


.slick-dots.dark button::before {
  color: #eeeeef;
}

.slick-dots.dark .slick-active button::before {
  color: #eeeeef;
}

.center div.slick-slide.slick-center {
  transform: scale(1);
  z-index: 100;
  transition: transform 0.25s ease-in;
}

.center div.slick-slide:not(.slick-center) {
  transform: scale(0.9);
  transition: transform 0.25s;
}

@media (min-width: 0) {
  .avatar {
    margin-top: 10px;
  }
}

@media (min-width: 600px) {
  .avatar {
    margin-top: -150px;
  }
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
